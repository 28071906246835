<template>
  <div class="single blog page">
    <section id="banner" class="align-middle">
      <banner-image-overlay></banner-image-overlay>
      <h2 class="title">{{ post.title }}</h2>
    </section>
    <div class="container 75%">
      <section class="myspanishnow-story row 200%">
        <div class="post-content" v-html="html"></div>
      </section>
    </div>
  </div>
</template>

<script>
import BannerImageOverlay from '../../../components/common/utils/BannerImageOverlay.vue';
import BlogPostsApi from '../../../api/BlogPosts';
import EditorJsToHtml from 'editorjs-html';

export default {
  name: 'ViewBlogPost',
  components: {
    BannerImageOverlay,
  },
  data() {
    return {
      editor: null,
      html: '',
      post: {
        id: 0,
        title: '',
        content: {},
        status: 'draft',
        permalink: '',
        content_overview: '',
        small_banner: undefined,
      },
    };
  },
  methods: {
    getBlogPost(permalink) {
      BlogPostsApi
        .get({permalink})
        .then(({ data }) => {
          this.post = data;
          this.initEditorJS();
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to retrieve blog post');
        });
    },
    delimiterBlock() {
      return '<div class="delimiter block"></div>';
    },
    attachesBlock({ data }) {
      return `<div class="attaches block"><a href="${data.file.url}" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/></svg>${data.title}</a></div>`;
    },
    initEditorJS() {
      this.parser = EditorJsToHtml({
        delimiter: this.delimiterBlock,
        attaches: this.attachesBlock,
      });
      for (let h of this.parser.parse(this.post.content)) {
        if (h.includes('<a href="')) {
          h = h.replace('<a href="', '<a target="_blank" href="');
        }
        else if (h.startsWith('<iframe ')) {
          h = `<div class="align-center embed">${h}</div>`;
        }
        else if (h.startsWith('<img ')) {
          h = `<div class="align-center image">${h}</div>`;
        }
        this.html += h;
      }
    },
  },
  created() {
    const permalink = this.$route.params.permalink;
    if (!permalink) {
      return;
    }

    this.getBlogPost(permalink);
  },
};
</script>

<style scoped>
  img {
    border-radius: 25px;
    width: 100%;
    margin-bottom: 2em;
  }

  .blog.single > .container {
    margin-top: 50px;
  }

  blockquote {
    margin-bottom: 2em;
  }

  blockquote p {
    margin: 0;
  }

  ul {
    list-style: none;
  }

  .post-content {
    width: 100%;
  }
</style>

<style>
  .post-content .delimiter {
    text-align: center;
  }

  .post-content .delimiter:before {
    display: inline-block;
    content: "***";
    font-size: 30px;
    line-height: 65px;
    height: 30px;
    letter-spacing: 0.2em;
  }

  .post-content .embed,
  .post-content .delimiter,
  .post-content .attaches,
  .post-content .image {
    margin: 0 0 2em 0;
  }

  .post-content .image img {
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .post-content .attaches svg {
    margin-left: 25px;
    margin-right: 5px;
  }

  .post-content .embed iframe {
    max-width: 100%;
    max-height: 100%;
    border-radius: 22px;
    overflow: hidden;
    -webkit-transform: translateZ(0);
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    box-shadow: 0 19px 51px 0 rgba(0,0,0,0.16), 0 14px 19px 0 rgba(0,0,0,0.07);
  }
</style>
